<template>
  <!-- Hero content: will be in the middle -->
  <section class="section">
    <div class="container">
      <div class="columns is-centered login-box">
        <div class="column is-4">
          <div class="columns is-multiline login">
            <div class="column is-12 box-auth">
              <div class="login-header">
                <h3 class="is-size-4 login-title">Login.</h3>
              </div>
              <div class="login-body">
                <form
                  @submit.prevent="processFormLogin(username, password)"
                  style="padding-bottom:1rem"
                >
                  <div class="form-group">
                    <b-field>
                      <template slot="label">
                        <span class="font-auth">YOUR E-MAIL</span>
                      </template>
                      <b-input
                        placeholder="Your Email"
                        type="is-info"
                        pack="fal"
                        v-model="username"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="form-group">
                    <b-field>
                      <template slot="label">
                        <span class="font-auth">PASSWORD</span>
                      </template>
                      <b-input
                        placeholder="Your Password"
                        type="password"
                        v-model="password"
                        password-reveal
                      ></b-input>
                    </b-field>
                  </div>
                  <div
                    style="display:flex;justify-content:space-between;padding-bottom:1rem"
                  >
                    <!-- <div>
                      <b-checkbox>Remember Me</b-checkbox>
                    </div> -->
                    <div>
                      <h4 class="is-size-7 button-forgot">
                        <router-link :to="{ name: `forgot` }">
                          <span class="is-size-6 login-subtitle"
                            >Forgot Password?</span
                          >
                        </router-link>
                      </h4>
                    </div>
                  </div>
                  <b-button
                    icon-left="lock"
                    tag="input"
                    native-type="submit"
                    type="is-hcc"
                    value="Login"
                    expanded
                  />
                </form>

                <!-- <div>
                  <span
                    >Don't have an account?
                    <span class="register">Register Now.</span></span
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null
    };
  },
  methods: {
    processFormLogin(username, password) {
      this.$store
        .dispatch("login", { username, password })
        .then(() => {
          this.success("Login Success");
        })
        .catch(() => {
          this.danger("Username or Password is incorrect");
        });
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .section {
    background-image: url("../../assets/loginpage.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
@media screen and (min-width: 1216px) {
  .section {
    background-image: url("../../assets/loginpage.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
  }
}

.login {
  border: 1px solid #d6dee3;
  border-radius: 10px;
  box-shadow: 0rem 0rem 1rem;

  @media screen and (min-width: 1024px) {
    &-box {
      margin-top: 12rem;
    }
  }
  @media screen and (min-width: 1408px) {
    &-box {
      margin-top: 16rem;
    }
  }

  &-header {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-title {
    color: #333d5d;
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &-subtitle {
    color: #b9bbc6;
  }

  &-body {
    padding: 30px;
  }
}
.form-group {
  margin-bottom: 1.7rem;
}

.register {
  color: #024886;
  font-weight: bold;
}

.logo {
  width: 60%;
  padding-left: 15rem;
}
</style>
